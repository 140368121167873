<template>
  <div>
    <v-icon color="green" class="" icon x-large @click="dialog = true"
      >mdi-plus-box</v-icon
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Add Employee</v-card-title>
        <v-card-text>
          <div>
            <v-form ref="form">
              <v-text-field
                label="Employee Name"
                v-model="form.name"
                prepend-icon="mdi-account-circle"
                :rules="rules"
              ></v-text-field>
              <v-text-field
                label="Contact Number"
                v-model="form.contact_no"
                prepend-icon="mdi-phone"
                :rules="rules"
              ></v-text-field>
              <v-text-field
                label="Address"
                v-model="form.address"
                prepend-icon="mdi-map-marker"
                :rules="rules"
              ></v-text-field>
              <div class="d-flex">
                <p>Birth Date</p>
                <input type="date" v-model="form.birth_date" class="" />
              </div>
              <v-select
                label="Position"
                :items="['Clerk', 'Admin Assistant', 'Technician']"
                prepend-icon="mdi-tools"
                :rules="rules"
                v-model="form.position"
              ></v-select>
              <v-text-field
                label="Daily Rate"
                v-model="form.daily_rate"
                prepend-icon="mdi-hand-coin"
                :rules="rules"
              ></v-text-field>
              <div class="d-flex">
                <p>Employment Date</p>
                <input type="date" v-model="form.date_of_employment" class="" />
              </div>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit" :loading="loading">Add</v-btn>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      form: {},
      rules: [(value) => !!value || "Field is Required."],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      add_employee: "employee/add_employee",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        let x = window.confirm(
          "Are you sure you want to add this new employee?"
        );
        if (x) {
          this.loading = true;
          this.add_employee(this.form)
            .then(() => {
              alert("Successfully added a new employee");
              this.loading = false;
            })
            .catch((err) => alert(err));
        }
      } else {
        alert("Please check some fields");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
