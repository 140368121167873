<template>
  <div>
    <v-icon color="yellow" @click="dialog = true">mdi-hand-coin</v-icon>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Add Employee Wage</v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <p class="mt-3">
            Employee Name: <span class="font-weight-bold">{{ item.name }}</span>
          </p>
          <p class="mt-n4">
            Position: <span class="font-weight-bold">{{ item.position }}</span>
          </p>
          <p class="mt-n4">
            Daily Rate:
            <span class="font-weight-bold">{{ item.daily_rate }} Php.</span>
          </p>
          <v-form ref="form">
            <div class="d-flex">
              <v-select
                :rules="rule"
                label="Half"
                prepend-icon=""
                :items="['1st Half', '2nd Half']"
                v-model="form.half"
              ></v-select>
              <v-select
                :rules="rule"
                label="For the month of"
                prepend-icon="mdi-calendar"
                :items="months"
                v-model="form.month"
              ></v-select>

              <v-select
                :rules="rule"
                class="ml-2"
                label="Year"
                :items="years"
                v-model="form.year"
              ></v-select>
            </div>
            <div class="d-flex">
              <v-text-field
                :rules="rule"
                label="Total Days Worked"
                type="number"
                prepend-icon="mdi-calendar"
                v-model="form.total_days_worked"
              ></v-text-field>
              <v-text-field
                label="Deductions"
                type="number"
                prepend-icon="mdi-cash-minus"
                v-model="form.deductions"
              ></v-text-field>
            </div>
            <v-text-field
              :rules="rule"
              prepend-icon="mdi-draw-pen"
              label="Notes for deductions"
              v-model="form.notes"
            ></v-text-field>
          </v-form>
          <h3 class="grey--text">Total Gross: {{ total_gross }}</h3>
          <h3 class="grey--text">Deductions: {{ form.deductions }}</h3>
          <p class="grey--text">
            Notes: <span class="font-weight-bold">{{ form.notes }}</span>
          </p>
          <v-divider class="mt-2 mb-3"></v-divider>
          <h2>
            Total Net:
            <span class="success--text">{{
              total_gross - form.deductions
            }}</span>
          </h2>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit()" :loading="loading"
            >Submit</v-btn
          >
          <v-btn color="success" @click="print()">Print</v-btn>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      rule: [(v) => !!v || "Item is required"],
      form: { deductions: 0, notes: "none" },
      dialog: false,
      loading: false,
      years: [2023, 2024, 2025, 2026],
      months: [
        "January",
        "Febuary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
    }),
    total_gross() {
      var total_gross = this.item.daily_rate * this.form.total_days_worked;
      return total_gross;
    },
  },
  methods: {
    ...mapActions({
      add_wage: "wage/add_wage",
    }),
    print() {
      var url =
        this.default_server_url +
        "/" +
        "print_pay_slip" +
        "/" +
        this.item.id +
        "/" +
        this.form.half +
        "/" +
        this.form.month +
        "/" +
        this.form.year;
      window.open(url);
    },
    submit() {
      // add employee id to form
      this.form.employee_id = this.item.id;
      //   add total gross and net to form
      this.form.total_gross = this.total_gross;
      this.form.total_net = this.total_gross - this.form.deductions;
      //   check if some fields are empty
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.loading = true;
        if (this.$refs.form.validate()) {
          this.add_wage(this.form)
            .then(() => {
              alert("Successfully inserted a wage");
              this.loading = false;
            })
            .catch((err) => {
              alert(err);
            });
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
